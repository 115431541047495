import { createApp } from 'vue'

// Pinia
import { createPinia } from 'pinia';

import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import './registerServiceWorker'
import router from './router'

const pinia = createPinia()

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
  })

createApp(App).use(router).use(pinia).use(vuetify).mount('#app')

// Manejar actualización del service worker
document.addEventListener('swUpdated', (event) => {
  const registration = event.detail;

  if (confirm('New content is available, would you like to refresh the page?')) {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });

      registration.waiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
});