<template>
  <v-app>
    <v-app-bar v-if="profileStore.auth" app color="blue" dark :elevation="5">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title>Inspecciones ALS - 3.0.24</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer class="v-navigation-drawer" v-model="drawer" temporary>
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item
          to="/censo"
          prepend-icon="mdi-forum"
          title="Censo"
          value="censo"
        ></v-list-item>
        <v-list-item
          to="/logout"
          prepend-icon="mdi-logout"
          title="Cerrar Sesión"
          value="logout"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { ref, provide } from "vue";
import useProfileStore from "@/store/profile.js";

export default {
  setup() {
    /* eslint-disable */
    const indexedDB = window.indexedDB;
    const conexion = indexedDB.open('BHP',1)

    let db 

    conexion.onsuccess = () => {
      db = conexion.result
      console.log('Base de Datos abierta', db)
    }

    conexion.onupgradeneeded = (e) => {
      console.log(e)
      db = conexion.result
      console.log('Base de Datos creada', db)
      const coleccionObjetosLotes = db.createObjectStore('Lotes', {
        keyPath: 'NUMERO_LOTE'
      })
      const coleccionObjetosLotesDetalles = db.createObjectStore('LotesDetalles', {
        keyPath: 'NUMERO_LOTE'
      })
      const coleccionObjetosInspecciones = db.createObjectStore('Inspecciones', {
        keyPath: 'numeroLote'
      })
    }

    conexion.onerror = (error) => {
      console.log('Error', error)
    }
    const message = ref(1);
    const drawer = ref(false);
    const profileStore = useProfileStore();

    provide("global-puerto", message);

    return {
      drawer,
      message,
      profileStore,
    };
  },
};
</script>
<style scoped>
.v-navigation-drawer {
  padding-top: 10px;
}
</style>
